@font-face {
  font-family: 'KONE';
  src: url('Components/webfonts/KONE_Information.ttf') format('truetype'), url('Components/webfonts/KONE_Information.eot') format('eot'), url('Components/webfonts/KONE_Information.woff') format('woff'), url('Components/webfonts/KONE_Information.woff2') format('woff2')  ;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  text-align: center;
	font-family:"KONE";
  display: flex;
  justify-content: center;
  align-items: center;
	min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./Components/loginImage.png');
  background-position: center;
}