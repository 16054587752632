.loginbg_minibox{
	display: flex; 
	place-content: center; 
	align-items: center; 
	height: 100%;
}

div#form-wrapper {
	padding: 20px;
	margin: 0 auto;
	width: 380px;
	-webkit-backdrop-filter: non;
	backdrop-filter: blur(12px);
	background: transparent;
	border: 1px solid rgba(250, 9, 9, 0.14);
}

.cclogo {
	margin: 0 auto; 
	width: 105px; 
	aspect-ratio: auto 105 / 40.5405; 
	height: 40.5405px;
}

.title {
	color: white;
	font-size: 30px;
	padding: 0;
	margin-bottom: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.title2 {
	color: white;
	font-size: 50px;
	padding: 0;
	margin-bottom: 0;
	margin-top: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.sso-btn {
  padding-top: 18px;
}

.loginsso{
	display: inline-block;  
	padding: 1px 18px; 
	margin-bottom: 0; 
	font-size: 16px; 
	font-weight: normal; 
	line-height: 35px; 
	text-align: center; 
	white-space: nowrap; 
	vertical-align: middle; 
	touch-action: manipulation; 
	cursor: pointer; 
	-webkit-user-select: none; 
	-moz-user-select: none; 
	-ms-user-select: none; 
	user-select: none; 
	background-image: none; 
	border-radius: 20px; 
	background-color: rgb(20, 80, 245);
  	color: white;
	border: 1px solid black; 
	width: 220px; 
	height: 35px;
  	font-family: "KONE";
}

.versionstr {
	z-index:11;
	position: absolute;
	bottom: 5px;
	right: 20px;
	color:white
}

/* Media queries for different devices */

/* Adjust layout for tablets */
@media (max-width: 768px) {
  .loginbg_minibox {
    padding: 30px;
    max-width: 80%;
  }
  .title {
    font-size: clamp(20px, 5vw, 24px);
  }
  .loginsso {
    font-size: 14px;
    padding: 10px;
  }
}

/* Adjust layout for mobile devices */
@media (max-width: 480px) {
  .loginbg_minibox {
    padding: 20px;
    max-width: 90%;
  }
  .title {
    font-size: clamp(18px, 6vw, 22px);
  }
  .loginsso {
    font-size: 14px;
    padding: 8px;
  }
}